import {gql} from '@apollo/client';

export const GetAttendeeQuery = gql`
  query QueryGuestSession {
    self {
      id
      attendeeTags: tags
      attendee {
        id
        name
        email
      }
      avatar
      chatTokens {
        token
      }
    }
  }
`;

export const VerifyGuestMutation = gql`
  mutation VerifyGuest($environmentId: UUID!, $accessCode: String!) {
    verifyGuest(
      data: {environmentId: $environmentId, accessCode: $accessCode}
    ) {
      id
      sessionToken
      attendeeTags: tags
      attendee {
        id
        name
        email
      }
      avatar
      chatTokens {
        token
      }
    }
  }
`;

export const VerifyOpenLoginMutation = gql`
  mutation VerifyOpenLogin($data: VerifyOpenLoginInput!) {
    verifyOpenLogin(data: $data) {
      __typename
      ... on OpenLoginGuest {
        id
        name
        email
        sessionToken
        chatTokens
        attendeeTags
      }
      ... on DataIntegrityError {
        code
        message
      }
    }
  }
`;

export const VerifyPublicPasscode = gql`
  mutation VerifyPublicPasscode($data: VerifyPublicPasscodeInput!) {
    verifyPublicPasscode(data: $data) {
      __typename
      ... on PublicPasscodeOutput {
        id
        name
        email
        sessionToken
        chatTokens
        attendeeTags
      }
      ... on DataIntegrityError {
        code
        message
      }
    }
  }
`;
