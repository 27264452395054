import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {AccessCodeInstructionSchema} from '@backstage-components/access-code';
import {
  assertNever,
  useShowInstructions,
  useSiteVersionId,
  type DeriveInstructionType,
} from '@backstage-components/base';
import {ComponentDefinition as OpenLoginComponent} from '@backstage-components/open-login';
import {PublicAccessCodeInstructionSchema} from '@backstage-components/public-access-code';
import {Type} from '@sinclair/typebox';
import {useMachine} from '@xstate/react';
import {useObservableState, useSubscription} from 'observable-hooks';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {filter, map} from 'rxjs';
import {Attendee, ContainerMachine} from './attendee-container-machine';
import {readAccessToken} from './attendee-session-token';
import {
  GuestAuthSuccessEvent,
  GuestAuthSuccessEventName,
} from './guest-authentication';

const InstructionSchema = Type.Union([
  ...AccessCodeInstructionSchema.anyOf,
  ...PublicAccessCodeInstructionSchema.anyOf,
  ...OpenLoginComponent.instructions.anyOf,
]);

type InstructionType = DeriveInstructionType<typeof InstructionSchema>;

type VerifyInstructionFilter<T extends string> = T extends `${string}:verify`
  ? T
  : never;

type ModuleType<T extends string> = T extends `${infer K}:verify` ? K : never;

type VerifyInstructionType = VerifyInstructionFilter<InstructionType['type']>;

type VerificationModule = ModuleType<VerifyInstructionType>;

interface AttendeeContextValue {
  attendeeId: string;
  attendeeName: string;
  attendeeEmail: string | null;
  attendeeType: Attendee['attendeeType'];
  token?: string;
  attendeeTags: string[];
  avatar?: string;
  sessionToken?: string;
}

interface AttendeeProviderProps<ApolloCache = NormalizedCacheObject> {
  client: ApolloClient<ApolloCache>;
  attendeeId?: string;
  showId: string;
}

/**
 * @private exported for tests
 */
export const AttendeeContainer = createContext<
  AttendeeContextValue | undefined
>(undefined);
AttendeeContainer.displayName = 'AttendeeContainer';

/**
 * Context `Provider` to create and hold an attendee record.
 */
export const AttendeeProvider: FC<PropsWithChildren<AttendeeProviderProps>> = (
  props
) => {
  const {client, showId} = props;
  const siteVersionId = useSiteVersionId();
  const [state, dispatch] = useMachine(ContainerMachine, {
    input: {client, showId},
  });
  // reset state machine when the `showId` sent to props changes
  useEffect(() => {
    dispatch({type: 'RESET', meta: {showId}});
  }, [dispatch, showId]);
  const attendee: Attendee | undefined = useMemo(() => {
    if (state.matches('success') && 'attendee' in state.context) {
      return {
        id: state.context.attendee.id,
        name: state.context.attendee.name,
        email: state.context.attendee.email,
        chatTokens: state.context.attendee.chatTokens.filter(
          (token) => token.token.length > 0
        ),
        attendeeTags: state.context.attendeeTags || [],
        attendeeType: state.context.attendee.attendeeType,
        avatar: state.context.attendee.avatar,
      };
    } else {
      return undefined;
    }
  }, [state]);
  // Listen for broadcasts from components of type `AccessCode` and `PublicAccessCode`.
  // when a verify request happens, forward it to the state machine.
  const {observable, broadcast} = useShowInstructions(InstructionSchema);
  const authModuleType = useObservableState(
    observable.pipe(
      map((instruction) => instruction.type),
      filter(
        (instructionType): instructionType is VerifyInstructionType =>
          instructionType === 'AccessCode:verify' ||
          instructionType === 'OpenLogin:verify' ||
          instructionType === 'PublicAccessCode:verify'
      ),
      map((instructionType): VerificationModule => {
        switch (instructionType) {
          case 'AccessCode:verify':
            return 'AccessCode';
          case 'OpenLogin:verify':
            return 'OpenLogin';
          case 'PublicAccessCode:verify':
            return 'PublicAccessCode';
          default:
            assertNever(instructionType);
        }
      })
    )
  );
  useSubscription(observable, {
    next: (instruction) => {
      if (
        instruction.type === 'AccessCode:verify' &&
        instruction.meta.showId !== showId
      ) {
        broadcast({
          type: 'AccessCode:failure',
          meta: {
            about: instruction.meta.about ?? undefined,
            reason: `ShowId mismatch expected: ${showId}, got: ${instruction.meta.showId}`,
          },
        });
      } else if (instruction.type === 'AccessCode:verify') {
        dispatch({
          type: 'VERIFY',
          meta: {
            about: instruction.meta.about,
            accessCode: instruction.meta.accessCode,
            showId,
            siteVersionId,
          },
        });
      } else if (
        instruction.type === 'OpenLogin:verify' &&
        instruction.meta.showId !== showId
      ) {
        broadcast({
          type: 'OpenLogin:failure',
          meta: {
            about: instruction.meta.about ?? undefined,
            reason: `ShowId mismatch expected: ${showId}, got: ${instruction.meta.showId}`,
          },
        });
      } else if (instruction.type === 'OpenLogin:verify') {
        dispatch({
          type: 'VERIFY_OPEN_LOGIN',
          meta: {
            about: instruction.meta.about,
            agreementAnswer: instruction.meta.agreementAnswer,
            agreementText: instruction.meta.agreementText,
            email: instruction.meta.email,
            moduleId: instruction.meta.moduleId,
            name: instruction.meta.name,
            showId,
            siteVersionId,
          },
        });
      } else if (
        instruction.type === 'PublicAccessCode:verify' &&
        instruction.meta.showId !== showId
      ) {
        broadcast({
          type: 'PublicAccessCode:failure',
          meta: {
            about: instruction.meta.about ?? undefined,
            reason: `ShowId mismatch expected: ${showId}, got: ${instruction.meta.showId}`,
          },
        });
      } else if (instruction.type === 'PublicAccessCode:verify') {
        dispatch({
          type: 'VERIFY_PUBLIC',
          meta: {
            about: instruction.meta.about,
            moduleId: instruction.meta.moduleId,
            passCode: instruction.meta.passCode,
            showId,
            siteVersionId,
            name: instruction.meta.name,
          },
        });
      }
    },
  });
  // When the state machine transitions, if `about` is set in context then
  // broadcast an instruction indicating success or failure.
  useEffect(() => {
    if (
      state.matches('failure') &&
      'reason' in state.context &&
      typeof authModuleType === 'string'
    ) {
      const meta = {about: state.context.about, reason: state.context.reason};
      broadcast({type: `${authModuleType}:failure`, meta});
    } else if (state.matches('success') && 'attendee' in state.context) {
      const {attendee} = state.context;
      if (attendee.attendeeType !== 'open-login') {
        const successType =
          attendee.attendeeType === 'pass-code'
            ? 'PublicAccessCode:success'
            : 'AccessCode:success';
        broadcast({
          type: successType,
          meta: {
            about: state.context.about,
            attendee: {
              chatTokens: attendee.chatTokens,
              email: attendee.email,
              id: attendee.id,
              name: attendee.name,
              tags: attendee.attendeeTags,
            },
          },
        });
      } else if (attendee.attendeeType === 'open-login') {
        broadcast({
          type: 'OpenLogin:success',
          meta: {
            about: state.context.about,
            attendee: {
              chatTokens: attendee.chatTokens,
              email: attendee.email,
              id: attendee.id,
              name: attendee.name,
              tags: attendee.attendeeTags,
            },
          },
        });
      } else {
        assertNever(attendee.attendeeType);
      }
    }
  }, [authModuleType, broadcast, state]);
  // Re-dispatch authentication success events as `GuestAuth:success`
  useEffect(() => {
    const onAccessCodeSuccess = (ev: GuestAuthSuccessEvent): void => {
      const eventName: GuestAuthSuccessEventName = 'GuestAuth:success';
      const autheEvent: GuestAuthSuccessEvent = new CustomEvent(eventName, {
        detail: {attendee: ev.detail.attendee, showId: ev.detail.showId},
      });
      document.body.dispatchEvent(autheEvent);
    };
    document.body.addEventListener('AccessCode:success', onAccessCodeSuccess);
    document.body.addEventListener('OpenLogin:success', onAccessCodeSuccess);
    document.body.addEventListener(
      'PublicAccessCode:success',
      onAccessCodeSuccess
    );
    return () => {
      document.body.removeEventListener(
        'AccessCode:success',
        onAccessCodeSuccess
      );
      document.body.removeEventListener(
        'OpenLogin:success',
        onAccessCodeSuccess
      );
      document.body.removeEventListener(
        'PublicAccessCode:success',
        onAccessCodeSuccess
      );
    };
  }, []);

  const value: AttendeeContextValue | undefined = useMemo(() => {
    if (attendee) {
      return {
        attendeeId: attendee.id,
        attendeeName: attendee.name,
        attendeeEmail: attendee.email,
        attendeeType: attendee.attendeeType,
        token: attendee.chatTokens[0]?.token,
        attendeeTags: attendee.attendeeTags ?? [],
        avatar: attendee.avatar,
        sessionToken: readAccessToken(showId) ?? undefined,
      };
    } else {
      return undefined;
    }
  }, [attendee, showId]);
  return <AttendeeContainer.Provider value={value} children={props.children} />;
};

/**
 * Gives access to the currently authenticated attendee if it exists.
 * @returns the attendee record if it exists, `null` if it does not.
 */
export const useAttendee = (): Attendee | null => {
  const context = useContext(AttendeeContainer);
  if (typeof context === 'undefined') {
    return null;
  } else {
    return attendeeFromContext(context);
  }
};

function attendeeFromContext(context: AttendeeContextValue): Attendee | null {
  if ('attendeeId' in context) {
    return {
      id: context.attendeeId,
      name: context.attendeeName,
      email: context.attendeeEmail ?? null,
      chatTokens:
        typeof context.token === 'string' ? [{token: context.token}] : [],
      attendeeTags: context.attendeeTags,
      attendeeType: context.attendeeType,
      avatar: context.avatar,
      sessionToken: context.sessionToken,
    };
  } else {
    return null;
  }
}
